// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as $$Image from "../components/Image.bs.js";
import * as React from "react";
import * as Resume from "../components/Resume.bs.js";

var root = CssJs.style([
      CssJs.display(CssJs.flexBox),
      CssJs.alignItems(CssJs.center),
      CssJs.backgroundColor(CssJs.rgb(235, 219, 178)),
      CssJs.display(CssJs.flexBox),
      CssJs.justifyContent(CssJs.center),
      CssJs.minHeight(CssJs.vh(100.0)),
      CssJs.padding2(CssJs.px(0), CssJs.px(16))
    ]);

var container = CssJs.style([
      CssJs.display(CssJs.flexBox),
      CssJs.justifyContent(CssJs.center),
      CssJs.media("(max-width: 768px)", [CssJs.flexDirection(CssJs.column)]),
      CssJs.width({
            NAME: "percent",
            VAL: 100.0
          })
    ]);

var Styles = {
  root: root,
  container: container
};

function Profile(props) {
  var profile = props.profile;
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: container
                }, React.createElement($$Image.make, {
                      avatar: profile.avatar
                    }), React.createElement(Resume.make, {
                      profile: profile
                    })));
}

var make = Profile;

export {
  Styles ,
  make ,
}
/* root Not a pure module */
