// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as React from "react";

var container = CssJs.style([
      CssJs.alignItems(CssJs.center),
      CssJs.display(CssJs.flexBox),
      CssJs.justifyContent(CssJs.center),
      CssJs.marginRight(CssJs.px(12)),
      CssJs.media("(max-width: 768px)", [CssJs.marginRight(CssJs.px(0))])
    ]);

var image = CssJs.style([
      CssJs.border(CssJs.px(6), CssJs.solid, CssJs.rgb(214, 93, 14)),
      CssJs.borderRadius({
            NAME: "percent",
            VAL: 25.0
          }),
      CssJs.height(CssJs.px(256)),
      CssJs.width(CssJs.px(256))
    ]);

var Styles = {
  container: container,
  image: image
};

function $$Image(props) {
  return React.createElement("section", {
              className: container
            }, React.createElement("img", {
                  className: image,
                  alt: "Avatar",
                  src: props.avatar
                }));
}

var make = $$Image;

export {
  Styles ,
  make ,
}
/* container Not a pure module */
