// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as React from "react";

var caption = CssJs.style([
      CssJs.fontSize(CssJs.rem(1.4)),
      CssJs.fontWeight(CssJs.medium),
      CssJs.lineHeight({
            NAME: "abs",
            VAL: 1.25
          }),
      CssJs.letterSpacing(CssJs.px(1)),
      CssJs.margin(CssJs.px(0))
    ]);

var Styles = {
  caption: caption
};

function Caption(props) {
  return React.createElement("h2", {
              className: caption
            }, props.children);
}

var make = Caption;

export {
  Styles ,
  make ,
}
/* caption Not a pure module */
