// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as React from "react";

var title = CssJs.style([
      CssJs.fontSize(CssJs.rem(3.0)),
      CssJs.fontWeight(CssJs.bold),
      CssJs.lineHeight({
            NAME: "abs",
            VAL: 1.25
          }),
      CssJs.letterSpacing(CssJs.px(1)),
      CssJs.margin(CssJs.px(0)),
      CssJs.position(CssJs.relative),
      CssJs.zIndex(1)
    ]);

var Styles = {
  title: title
};

function Title(props) {
  return React.createElement("header", undefined, React.createElement("h1", {
                  className: title,
                  id: props.id
                }, props.children));
}

var make = Title;

export {
  Styles ,
  make ,
}
/* title Not a pure module */
