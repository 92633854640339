// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as React from "react";
import * as Client from "react-dom/client";

var domElement = document.querySelector("#app-root");

if (!(domElement == null)) {
  var root = Client.createRoot(domElement);
  root.render(React.createElement(App.make, {}));
}

export {
  
}
/* domElement Not a pure module */
