// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as Title from "./Title.bs.js";
import * as React from "react";
import * as GitHub from "./GitHub.bs.js";
import * as Caption from "./Caption.bs.js";
import * as Discord from "./Discord.bs.js";
import * as Linkedin from "./Linkedin.bs.js";
import * as DateFns$ReasonDateFns from "reason-date-fns/src/DateFns.bs.js";

function rs(prim) {
  return prim;
}

var section = CssJs.style([
      CssJs.color(CssJs.rgb(60, 56, 54)),
      CssJs.display(CssJs.flexBox),
      CssJs.flexDirection(CssJs.column),
      CssJs.marginLeft(CssJs.px(12)),
      CssJs.media("(max-width: 768px)", [CssJs.alignSelf(CssJs.center)])
    ]);

var details = CssJs.style([
      CssJs.fontSize(CssJs.rem(0.8)),
      CssJs.marginTop(CssJs.px(8))
    ]);

var paragraph = CssJs.style([CssJs.margin2(CssJs.px(8), CssJs.px(0))]);

var link = CssJs.style([
      CssJs.color(CssJs.rgb(7, 102, 120)),
      CssJs.marginRight(CssJs.px(24))
    ]);

var icon = CssJs.style([CssJs.marginTop(CssJs.px(16))]);

var Styles = {
  section: section,
  details: details,
  paragraph: paragraph,
  link: link,
  icon: icon
};

function Resume$Item(props) {
  var sufix = props.sufix;
  var prefix = props.prefix;
  var tmp;
  tmp = typeof prefix === "number" ? null : prefix._0;
  var tmp$1;
  tmp$1 = typeof sufix === "number" ? null : (
      sufix.TAG === /* Component */0 ? sufix._0 : React.createElement("span", undefined, sufix._0)
    );
  return React.createElement("p", {
              className: paragraph
            }, tmp, tmp$1);
}

var Item = {
  make: Resume$Item
};

function Resume$Email(props) {
  var href = props.href;
  return React.createElement("a", {
              className: link,
              href: "mailto:" + href + "?Subject=Whats%20up!",
              target: "_top"
            }, href);
}

var Email = {
  make: Resume$Email
};

function Resume$Link(props) {
  return React.createElement("a", {
              className: link,
              href: props.href,
              rel: "noopener noreferrer",
              target: "_blank"
            }, props.children);
}

var Link = {
  make: Resume$Link
};

function Resume(props) {
  var profile = props.profile;
  var age = DateFns$ReasonDateFns.differenceInYears(new Date(profile.birth), new Date()).toString();
  return React.createElement("section", {
              className: section
            }, React.createElement(Title.make, {
                  children: profile.name,
                  id: profile.name
                }), React.createElement(Caption.make, {
                  children: profile.role
                }), React.createElement("div", {
                  className: details
                }, React.createElement(Resume$Item, {
                      prefix: {
                        TAG: /* String */1,
                        _0: "Age: "
                      },
                      sufix: {
                        TAG: /* String */1,
                        _0: age
                      }
                    }), React.createElement(Resume$Item, {
                      prefix: {
                        TAG: /* String */1,
                        _0: "Email: "
                      },
                      sufix: {
                        TAG: /* Component */0,
                        _0: React.createElement(Resume$Email, {
                              href: profile.email
                            })
                      }
                    }), React.createElement(Resume$Item, {
                      prefix: {
                        TAG: /* String */1,
                        _0: "Location: "
                      },
                      sufix: {
                        TAG: /* String */1,
                        _0: profile.location
                      }
                    }), React.createElement(Resume$Item, {
                      prefix: /* None */0,
                      sufix: {
                        TAG: /* Component */0,
                        _0: React.createElement(Resume$Link, {
                              href: profile.media.skillset,
                              children: "SkillSet"
                            })
                      }
                    })), React.createElement("div", {
                  className: icon
                }, React.createElement(Resume$Link, {
                      href: profile.media.discord,
                      children: React.createElement(Discord.make, {})
                    }), React.createElement(Resume$Link, {
                      href: profile.media.linkedin,
                      children: React.createElement(Linkedin.make, {})
                    }), React.createElement(Resume$Link, {
                      href: profile.media.github,
                      children: React.createElement(GitHub.make, {})
                    })));
}

var make = Resume;

export {
  rs ,
  Styles ,
  Item ,
  Email ,
  Link ,
  make ,
}
/* section Not a pure module */
