// Generated by ReScript, PLEASE EDIT WITH CARE


var raw = {
  profile: {
    name: "Fabiano Luiz",
    avatar: "https://avatars.githubusercontent.com/u/50997688?v=4",
    email: "fabianolvs3@gmail.com",
    birth: "1999-09-20",
    location: "Belo Horizonte - MG",
    role: "Software Engineer",
    media: {
      linkedin: "https://www.linkedin.com/in/fabiano-luiz-531318182/",
      github: "https://github.com/fabinzne",
      discord: "https://discordapp.com/users/325447369942368256",
      twitter: "https://twitter.com/fabinzne",
      skillset: "https://notch-lamp-2b4.notion.site/SkillSet-9636364c1b824dd8a81713d1aba08448"
    }
  }
};

export {
  raw ,
}
/* No side effect */
