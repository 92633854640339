// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Data from "./data/Data.bs.js";
import * as React from "react";
import * as Profile from "./pages/Profile.bs.js";

function App(props) {
  return React.createElement(Profile.make, {
              profile: Data.raw.profile
            });
}

var make = App;

export {
  make ,
}
/* react Not a pure module */
